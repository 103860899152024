import React, { useState, useEffect, useReducer } from 'react';
import { useRouter } from 'next/router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import i18n from '../../i18n';
import Button from '../Button';
import CardGuide from '../CardGuide';
import GuideFilter from '../GuideFilter';
import styles from './GuideList.module.scss';
import { guidesApi, abortGuideRequests } from '../../utils/api';
import { removeSavedId, MY_LIST_KEY_GUIDES } from '../../utils/MyListStorage';
import { dataLayerPush } from '../../utils/datalayer';
import { capitalizeFirstLetter } from '../../utils/helpers';
import isEmpty from '../../utils/isEmpty';

function _filterNull(obj) {
    const ret = {};
    for (const key in obj) {
        if (obj[key] !== null) {
            ret[key] = obj[key];
        }
    }
    return ret;
}

function reducer(state, action) {
    switch (action.type) {
        case 'requestStarted':
            return { ...state, loading: true };
        case 'requestFailed':
            return { ...state, loading: false, error: true };
        case 'setGuides':
            return {
                ...state,
                guides: [...action.guides],
            };
        case 'guidesReceived':
            const { hasMoreContent } = action;
            const guides = [...state.guides, ...action.guides];
            const nextPage = hasMoreContent ? state.page + 1 : null;
            return {
                ...state,
                guides,
                hasMoreContent,
                nextPage,
                loading: false,
                error: false,
            };
        case 'loadMore':
            return { ...state, page: state.nextPage };
        case 'categoryClicked':
            const { activeTags } = action;
            return {
                ...state,
                selectedCategories: activeTags,
                page: 1,
                guides: [],
            };

        default:
            throw new Error('Unknown action: ' + action.type);
    }
}

const GuideList = ({
    title,
    items,
    filters,
    isMyList,
    showRemoveButtons,
    isEmptyMyList,
    onLocalsPage,
    categories,
    extraPaddingTop,
}) => {
    const router = useRouter();
    const page = router?.query?.page ?? 1;
    const currentPage = Number.isInteger(parseInt(page)) ? parseInt(page) : 1;
    const initialState = {
        selectedCategories: [],
        guides: [],
        categories: categories,
        hasMoreContent: null,
        relatedEmpty: null,
        page: currentPage,
        loading: false,
        error: false,
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const fetchData = () => {
        if (state.loading) {
            abortGuideRequests();
        }

        dispatch({ type: 'requestStarted' });

        /**
         * Request arguments:
         *
         * page?: number;
         * size?: number;
         * categories: string?
         */
        const args = {
            page: state.page,
            size: 8,
            tags: state.selectedCategories.length
                ? state.selectedCategories
                : null,
        };

        if (!isEmpty(initialCategories)) {
            args.categories = initialCategories;
        }
        const reqArgs = _filterNull(args);
        // Introduce a delay of 5 seconds before fetching the data
        setTimeout(() => {
            guidesApi
                .listGuidePages(reqArgs)
                .then((data) => {
                    dispatch({
                        type: 'guidesReceived',
                        guides: data.results,
                        hasMoreContent: !!data.next,
                    });
                })
                .catch((e) => {
                    // Not an error, we do this every time filter changes while querying the api
                    if (e.name === 'AbortError') {
                        return;
                    }

                    dispatch({ type: 'requestFailed' });
                    console.error(e);
                });
        }, 250);
    };

    const MIN_VISIBLE_FILTERS = 5;
    const initialCategories = categories.map((category) => category.slug);
    const [showAllFilters, setShowAllFilters] = useState(false);
    const [filterArray, setFilterArray] = useState([]);
    const [visibleFilters, setVisibleFilters] = useState([]);
    const [filteredCards, setFilteredCards] = useState(items);
    const isFullWidth = Boolean(items.length === 1) && !isMyList;

    useEffect(() => {
        if (!isEmpty(items)) {
            dispatch({
                type: 'guidesReceived',
                guides: items,
                hasMoreContent: false,
            });
        }
        if (filters) {
            setFilterArray(filters);
            setVisibleFilters(filters.slice(0, MIN_VISIBLE_FILTERS));
        }
    }, [items, filters, isMyList, onLocalsPage]);

    useEffect(() => {
        if (isEmpty(items)) {
            fetchData();
        }
    }, [state.selectedCategories, state.page, items]);

    const handleLoadMore = (e) => {
        e.preventDefault();
        dataLayerPush({ event: 'showMoreCategoryPage' });
        dispatch({ type: 'loadMore' });
    };

    const removeItemsFromList = (i) => {
        removeSavedId(MY_LIST_KEY_GUIDES, filteredCards[i].id);
        setFilteredCards((prevFilteredCards) => {
            const prevCards = prevFilteredCards.filter(
                (_, index) => index !== i
            );

            dispatch({
                type: 'setGuides',
                guides: prevCards,
            });

            return prevCards;
        });
    };

    const handleTagClick = (i) => {
        let tags = [...filterArray];
        const { text, active } = filterArray[i];

        tags[i].active = !active;
        setFilterArray(tags);

        if (tags[i].active) {
            dataLayerPush({
                event: 'menu',
                eventCategory: 'Menu',
                eventAction: '3',
                eventLabel: capitalizeFirstLetter(text),
            });
        }
        dispatch({
            type: 'categoryClicked',
            activeTags: tags.filter((f) => f.active).map((f) => f.slug),
        });
    };

    const toggleFilters = () => {
        if (showAllFilters) {
            setVisibleFilters(filterArray.slice(0, MIN_VISIBLE_FILTERS));
        } else {
            setVisibleFilters(filterArray);
        }
        setShowAllFilters(!showAllFilters);
    };

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
    });

    const showTitle =
        (Boolean(filterArray.length) && !isMyList) || isEmptyMyList || title;

    return (
        <div
            className={classNames(styles['GuideList'], {
                [styles['GuideList--MyList']]: isMyList || onLocalsPage,
                [styles['GuideList--isVisible']]: inView,
                [styles['GuideList--ExtraPaddingTop']]: extraPaddingTop,
            })}>
            {showTitle && (
                <h2
                    className={classNames(styles['GuideList__Title'], {
                        [styles['GuideList__Title--Left']]:
                            !Boolean(visibleFilters.length) &&
                            !isMyList &&
                            !isEmptyMyList,
                        [styles['GuideList__Title--EmptyMyList']]:
                            !Boolean(visibleFilters.length) && isEmptyMyList,
                    })}>
                    {title && !isEmptyMyList
                        ? title
                        : i18n.t('Guides.emptyMyList')}
                </h2>
            )}

            {Boolean(visibleFilters.length) && (
                <div
                    className={styles['GuideList__FilterContainer']}
                    role="group"
                    aria-label={title}>
                    {visibleFilters.map((filter, i) => {
                        return (
                            <GuideFilter
                                key={i}
                                {...filter}
                                active={filter.active}
                                onClick={() => handleTagClick(i)}
                            />
                        );
                    })}

                    {filterArray.length > MIN_VISIBLE_FILTERS && (
                        <GuideFilter
                            color="white"
                            text={
                                visibleFilters.length > MIN_VISIBLE_FILTERS
                                    ? `- ${i18n.t('generic.less')}`
                                    : `+ ${i18n.t('generic.more')}`
                            }
                            onClick={toggleFilters}
                        />
                    )}
                </div>
            )}
            <div ref={ref} />
            <div
                className={classNames(styles['GuideList__CardsContainer'], {
                    [styles['GuideList__CardsContainer--MyList']]:
                        isMyList || onLocalsPage,
                    [styles['GuideList__CardsContainer--FullWidth']]:
                        isFullWidth,
                })}>
                {state.guides && (
                    <TransitionGroup
                        className={styles['GuideList__Cards']}
                        enter={true}
                        exit={true}>
                        {state.guides.map((card, i) => {
                            console.log(card);

                            return (
                                <CSSTransition
                                    key={card.id}
                                    transitionAppear={true}
                                    classNames={{
                                        enter: styles[
                                            'GuideList__animation-enter'
                                        ],
                                        enterActive:
                                            styles[
                                                'GuideList__animation-enter-active'
                                            ],
                                        exit: styles[
                                            'GuideList__animation-exit'
                                        ],
                                        exitActive:
                                            styles[
                                                'GuideList__animation-exit-active'
                                            ],
                                    }}
                                    timeout={250}>
                                    <CardGuide
                                        {...card}
                                        modifiers={[styles['GuideList__Card']]}
                                        showRemoveButton={showRemoveButtons}
                                        fullWidth={isFullWidth}
                                        onLocalsPage={onLocalsPage}
                                        inMyList={isMyList}
                                        onClick={
                                            isMyList
                                                ? () => removeItemsFromList(i)
                                                : null
                                        }
                                    />
                                </CSSTransition>
                            );
                        })}
                    </TransitionGroup>
                )}
                {state.error && (
                    <div className={styles['GuideList__Error']}>
                        {' '}
                        {i18n.t('Event.loadingError')}{' '}
                    </div>
                )}

                {state.guides && state.guides.length < 0 && (
                    <div className={styles['GuideList__NoResults']}>
                        {i18n.t('Event.noResults')}
                    </div>
                )}

                <div
                    style={{
                        visibility: state.loading ? 'visible' : 'hidden',
                    }}
                    className={styles['GuideList__LoaderContainer']}>
                    <div className={styles['GuideList__Loader']} />
                    <div className={styles['GuideList__Loader']} />
                </div>

                {state.hasMoreContent && !isMyList && !state.loading && (
                    <Button
                        modifiers={[styles['GuideList__Button']]}
                        type="secondaryInvert"
                        text={i18n.t('Event.loadMore')}
                        onClick={(e) => handleLoadMore(e)}
                        href={`?page=${state.nextPage}`}
                    />
                )}
            </div>
        </div>
    );
};

GuideList.propTypes = {
    filters: PropTypes.array,
    items: PropTypes.array,
    relatedItems: PropTypes.array,
    title: PropTypes.string,
    isMyList: PropTypes.bool,
    showRemoveButtons: PropTypes.bool,
    isEmptyMyList: PropTypes.bool,
    onLocalsPage: PropTypes.bool,
    categories: PropTypes.array,
    extraPaddingTop: PropTypes.bool,
};

GuideList.defaultProps = {
    filters: null,
    items: [],
    relatedItems: [],
    title: null,
    isMyList: false,
    showRemoveButtons: false,
    isEmptyMyList: false,
    onLocalsPage: false,
    categories: [],
    extraPaddingTop: false,
};

export default GuideList;
